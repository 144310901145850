import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { Container, Nav, Navbar, NavbarProps } from 'react-bootstrap'
import logo from '../../images/logo.svg'
import { Must, SiteTitleQuery } from '../../types'
import { Footer } from './Footer'
import { NavLink } from './NavLink'

interface LayoutProps {
  children?: React.ReactNode
  className?: string
  overlay?: boolean
}

export const Layout = ({ children, className, overlay }: LayoutProps) => {
  const data: Must<SiteTitleQuery> = useStaticQuery(graphql`
    query SiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const navbarProps: NavbarProps = overlay ? { fixed: 'top' } : { sticky: 'top' }

  return (
    <div className={className} id="layout">
      <Navbar expand="lg" {...navbarProps} className="mainnav">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt={data.site.siteMetadata.title} className="pr-2" width={300} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto" as="ul">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/collections/" partiallyActive>
                Collections
              </NavLink>
              {/* <NavLink to="/press/">Press</NavLink> */}
              <NavLink to="/about/">About</NavLink>
              <NavLink to="/contact/">Contact</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <main>{children}</main>
      <Footer />
    </div>
  )
}
